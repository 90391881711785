<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：学会管理 > <b>人员管理</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="personnel-info">
            <div class="personnel-condition">
                <div class="flex-center">
                    <div class="send-condition-item">
                        <el-select v-model="department" placeholder="请选择部门">
                            <el-option
                                    v-for="item in departmentOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="send-condition-item">
                        <el-select v-model="state" placeholder="账号状态">
                            <el-option
                                    v-for="item in stateOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <el-input
                            v-model="search_content"
                            placeholder="请输入姓名"
                    ></el-input>
                    <el-button type="primary" @click="search">搜索</el-button>
                </div>
                <el-button type="primary" @click="showEditDialog(false)"
                >添加人员
                </el-button
                >
            </div>
            <div class="personnel-list">
                <el-table
                        :data="tableData"
                        header-row-class-name="table-header"
                        style="width: 100%"
                >
                    <el-table-column label="ID" width="100">
                        <template slot-scope="scope">
                            <div class="table-id">
                                {{ scope.row.user_id }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.true_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="账号">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.phone }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="职务">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.job }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属部门">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.department }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="状态">
                        <template slot-scope="scope">
                            <div
                                    class="table-item-state"
                                    :class="{ active: scope.row.is_locking === 0 }"
                            >
                                {{ scope.row.is_locking === 0 ? "启用" : "停用" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" width="350">
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <el-button
                                        class="reset-pass"
                                        @click="showReset(scope.row)"
                                        type="text">重置密码
                                </el-button>
                                <el-button @click="showEditDialog(scope.row)" type="text">修改
                                </el-button>
                                <el-button
                                        class="delete"
                                        @click="showDelDialog(scope.row)"
                                        type="text">删除
                                </el-button>
                                <el-button @click="showStop(scope.row)" type="text">
                                    {{ scope.row.is_locking == 1 ? "启用" : "停用" }}
                                </el-button>
                                <!--                                TODO 解锁项目-->
                                    <el-switch
                                        v-model="scope.row.is_lock_role"
                                        :inactive-value="0"
                                        :active-value="1"
                                        active-color="#13ce66"
                                        inactive-color="#eee" :active-text="`${scope.row.is_lock_role ? '有' : '无'}解锁项目权限`"
                                        @change="($event)=> lockRoleChange($event,scope.row.user_id)"
                                        v-if="isHuiZhang && scope.row.user_id !== config.zhangque">
                                    </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="page-all"
                        background
                        layout="prev, pager, next"
                        :page-size="limit"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                :visible.sync="editVisible"
                width="30%"
                center
                :before-close="closeEdit"
        >
      <span slot="title" class="dialog-title">{{
          cur_id !== -1 ? "修改人员" : "添加人员"
          }}</span>
            <div class="dialog-container flex-center">
                <div>姓名</div>
                <el-input v-model="edit_name" placeholder="请输入姓名"></el-input>
            </div>
            <div class="dialog-container flex-center">
                <div>账号</div>
                <el-input v-model="edit_account" placeholder="请输入账号"></el-input>
            </div>
            <div class="dialog-container flex-center">
                <div>部门</div>
                <el-select v-model="edit_department" placeholder="请选择部门" @change="departmentChange">
                    <el-option
                            v-for="item in departmentSelect"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="dialog-container flex-center">
                <div>担任第二组长</div>
                <el-select v-model="twice_group_leader" clearable placeholder="请选择担任组长的部门">
                    <el-option
                            v-for="item in allTeams"
                            :key="item.id"
                            :label="item.group_name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="dialog-container flex-center" v-if="edit_teams.length > 0">
                <div>小组</div>
                <el-select v-model="edit_team" placeholder="请选择部门小组">
                    <el-option
                            v-for="item in edit_teams"
                            :key="item.id"
                            :label="item.group_name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="dialog-container flex-center">
                <div>职务</div>
                <el-select v-model="jid" placeholder="请输入职务">
                    <el-option
                            v-for="item in jobOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="dialog-container flex-center" v-if="cur_id == -1">
                <div>密码</div>
                <el-input disabled value="123456"></el-input>
            </div>
            <div class="dialog-container flex-center">
                <div>验证码<span style="color: #ffffff">吗</span></div>
                <el-input v-model="edit_code" type="number" placeholder="请输入验证码"></el-input>
                <el-button class="margin-left" type="primary" @click="sendCode">{{codeText}}</el-button>
            </div>
            <div class="dialog-container flex-center" v-if="edit_teams.length > 0">
                <div style="padding-right: 20px">部门组长</div>
                <el-switch
                        v-model="is_group_leader"
                        active-color="#13ce66"
                        inactive-color="#eee"
                >
                </el-switch>
            </div>
            <div class="dialog-container flex-center">
                <div style="padding-right: 20px">管理权限</div>
                <el-switch
                        v-model="if_admin"
                        active-color="#13ce66"
                        inactive-color="#eee"
                >
                </el-switch>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible.sync="delVisible"
                width="30%"
                center
                :before-close="closeEdit">
            <span slot="title" class="dialog-title">温馨提示</span>
            <div class="dialog-container">
                <div>此操作将删除该人员, 是否继续?</div>
            </div>
            <div class="dialog-container flex-center">
                <div>验证码<span style="color: #ffffff">吗</span></div>
                <el-input v-model="edit_code" type="number" placeholder="请输入验证码"></el-input>
                <el-button class="margin-left" type="primary" @click="sendCode">{{codeText}}</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delPersonnel">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible.sync="resetVisible"
                width="30%"
                center
                :before-close="closeEdit">
            <span slot="title" class="dialog-title">温馨提示</span>
            <div class="dialog-container">
                <div>此操作将重置该人员登录密码, 是否继续?</div>
            </div>
            <div class="dialog-container flex-center">
                <div>验证码<span style="color: #ffffff">吗</span></div>
                <el-input v-model="edit_code" type="number" placeholder="请输入验证码"></el-input>
                <el-button class="margin-left" type="primary" @click="sendCode">{{codeText}}</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetPass">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible.sync="stopVisible"
                width="30%"
                center
                :before-close="closeEdit">
            <span slot="title" class="dialog-title">温馨提示</span>
            <div class="dialog-container">
                <div>此操作将{{cur_data.is_locking === 0 ? "停" : "启"}}用该人员登录, 是否继续?</div>
            </div>
            <div class="dialog-container flex-center">
                <div>验证码<span style="color: #ffffff">吗</span></div>
                <el-input v-model="edit_code" type="number" placeholder="请输入验证码"></el-input>
                <el-button class="margin-left" type="primary" @click="sendCode">{{codeText}}</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setLock">确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import config from "../../config";

    export default {
        name: "personnel",
        data() {
            return {
                config:config,
                department: "",
                departmentOptions: [],
                jid: "",
                is_group_leader: false,
                if_admin: false,
                jobOptions: [],
                state: "",
                stateOptions: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "停用",
                    },
                    {
                        value: 2,
                        label: "启用",
                    },
                ],
                search_content: "",
                editVisible: false,
                delVisible: false,
                resetVisible: false,
                stopVisible: false,
                cur_id: -1, //修改用户信息时选中的ID，新建时没有
                cur_data: '', //修改用户信息时选中的ID，新建时没有
                edit_code: "",
                edit_name: "", //编辑时的用户名字
                edit_account: "", //编辑时的用户账号
                edit_team: "", //编辑时的用户部门
                edit_teams: [], //编辑时的用户部门
                edit_department: "", //编辑时的用户部门
                twice_group_leader: '', //编辑时的用户部门
                allTeams: [],
                tableData: [],
                page: 1, //
                pageTotal: 1,
                limit: 15,
                id: null, // 计时器id
                timeId: null, // 一次性计时器id
                codeText: '获取验证码', // 验证码文字
            };
        },
        created() {
            this.$emit("setIndex", [2, 2]);
            this.getClassifyList();
            this.init();
            this.getAllTeamList()
        },
        methods: {
            showEditDialog(data) {
                this.editVisible = true;
                if (data) {
                    this.cur_id = data.user_id;
                    this.edit_name = data.true_name;
                    this.edit_account = data.phone;
                    this.edit_department = data.did;
                    this.twice_group_leader = data.twice_group_leader ?  data.twice_group_leader : '';
                    this.edit_team = data.group_team;
                    this.jid = data.jid;
                    this.is_group_leader = data.is_group_leader ? true : false;
                    this.if_admin = data.if_admin ? true : false;
                    this.getTeamList()
                }
            },
            showDelDialog(data) {
                this.delVisible = true
                this.cur_data = data
            },
            showReset(data) {
                this.resetVisible = true
                this.cur_data = data
            },
            showStop(data) {
                this.stopVisible = true
                this.cur_data = data
            },
            closeEdit() {
                this.editVisible = false;
                this.delVisible = false;
                this.resetVisible = false;
                this.stopVisible = false;
                this.cur_id = -1;
                this.cur_data = '';
                this.edit_code = "";
                this.edit_name = "";
                this.edit_account = "";
                this.edit_department = "";
                this.twice_group_leader = '';
                this.jid = "";
            },
            //  获取验证码
            sendCode() {
                if (this.codeText === '获取验证码') {
                    this.$api.apiContent.SendMsg({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        }, this.$store.state.user.key)
                    }).then(() => {
                        this.codeText = 60
                        this.id = setInterval(() => {
                            if (this.codeText === 0) {
                                this.codeText = '获取验证码'
                                return
                            }
                            if (typeof this.codeText === 'number') {
                                this.codeText--
                            }
                        }, 1000)
                    }).catch(e => {
                        this.$message.error(e.msg)
                        this.codeText = '获取验证码'
                        clearInterval(this.id)
                    })
                } else {
                    this.$message.error('请勿重复发送')
                }
            },
            submit() {
                if (!this.edit_name) {
                    this.$message.error("请输入姓名");
                    return;
                }
                if (!this.edit_account) {
                    this.$message.error("请输入账号");
                    return;
                }
                if (!this.edit_department) {
                    this.$message.error("请选择部门");
                    return;
                }
                if (!this.jid) {
                    this.$message.error("请输入职务");
                    return;
                }
                if (!this.edit_code) {
                    this.$message.error("请输入验证码")
                    return
                }
                if (this.cur_id != -1) {
                    //修改
                    this.$api.apiContent
                        .userEdit({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.cur_id,
                            jid: this.jid,
                            did: this.edit_department,
                            twice_group_leader: this.twice_group_leader ? this.twice_group_leader : 0,
                            name: this.edit_name,
                            phone: this.edit_account,
                            group_team: this.edit_team,
                            is_group_leader: this.is_group_leader ? 1 : 0,
                            if_admin: this.if_admin ? 1 : 0,
                            code: this.edit_code,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.cur_id,
                                    jid: this.jid,
                                    did: this.edit_department,
                                    twice_group_leader: this.twice_group_leader ? this.twice_group_leader : 0,
                                    name: this.edit_name,
                                    phone: this.edit_account,
                                    group_team: this.edit_team,
                                    is_group_leader: this.is_group_leader ? 1 : 0,
                                    if_admin: this.if_admin ? 1 : 0,
                                    code: this.edit_code,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("修改成功");
                            this.init();
                            this.closeEdit()
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                        });
                } else {
                    //添加
                    this.$api.apiContent
                        .userAdd({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            jid: this.jid,
                            did: this.edit_department,
                            twice_group_leader: this.twice_group_leader ? this.twice_group_leader : 0,
                            pass: "123456",
                            name: this.edit_name,
                            phone: this.edit_account,
                            group_team: this.edit_team,
                            is_group_leader: this.is_group_leader ? 1 : 0,
                            if_admin: this.if_admin ? 1 : 0,
                            code: this.edit_code,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    jid: this.jid,
                                    did: this.edit_department,
                                    twice_group_leader: this.twice_group_leader ? this.twice_group_leader : 0,
                                    pass: "123456",
                                    name: this.edit_name,
                                    phone: this.edit_account,
                                    group_team: this.edit_team,
                                    is_group_leader: this.is_group_leader ? 1 : 0,
                                    if_admin: this.if_admin ? 1 : 0,
                                    code: this.edit_code,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("添加成功");
                            this.init();
                            this.closeEdit()
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                        });
                }
            },
            //设置启用/停用
            setLock() {
                if (!this.edit_code) {
                    this.$message.error("请输入验证码")
                    return
                }
                let that = this;
                let state = that.cur_data.is_locking
                that.$api.apiContent
                    .setLock({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        state: state === 0 ? 1 : 0,
                        id: this.cur_data.user_id,
                        code: this.edit_code,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                state: state === 0 ? 1 : 0,
                                id: this.cur_data.user_id,
                                code: this.edit_code,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.$message({
                            message: (state === 0 ? "停" : "启") + "用成功",
                            type: "success",
                            duration: 2000,
                        });
                        that.init();
                        that.closeEdit()
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    })
            },
            lockRoleChange(e,user_id) {
                let that = this;
                let state = e
                that.$api.apiContent
                    .setUnlockRole({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        is_lock_role: state,
                        user_id: user_id,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                is_lock_role: state,
                                user_id: user_id,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.$message({
                            message: (state === 0 ? "停" : "启") + "用成功",
                            type: "success",
                            duration: 2000,
                        });
                        that.init();
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    })
            },
            //重置密码
            resetPass() {
                if (!this.edit_code) {
                    this.$message.error("请输入验证码")
                    return
                }
                let that = this;
                that.$api.apiContent
                    .userResetPass({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.cur_data.user_id,
                        code: this.edit_code,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: this.cur_data.user_id,
                                code: this.edit_code,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.$message({
                            message: "重置成功",
                            type: "success",
                            duration: 2000,
                        });
                        that.closeEdit()
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    });
            },
            //删除人员
            delPersonnel() {
                if (!this.edit_code) {
                    this.$message.error("请输入验证码")
                    return
                }
                let that = this;
                that.$api.apiContent
                    .userDel({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.cur_data.user_id,
                        code: this.edit_code,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: this.cur_data.user_id,
                                code: this.edit_code,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.$message({
                            message: "删除成功",
                            type: "success",
                            duration: 2000,
                        });
                        that.init();
                        that.closeEdit()
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    })
            },

            //  搜索
            search() {
                this.page = 1;
                this.init();
            },
            // 分页变化
            setPage(val) {
                this.page = val;
                this.init();
            },
            //获取分类信息 职位列表、部门列表
            getClassifyList() {
                this.$api.apiContent
                    .getJobList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.jobOptions = res.data;
                    })
                    .catch(() => {
                        this.jobOptions = [];
                    });
                this.$api.apiContent
                    .bumen({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.departmentOptions = [
                            ...[
                                {
                                    id: 0,
                                    name: "全部",
                                },
                            ],
                            ...res.data,
                        ];
                    })
                    .catch(() => {
                        this.departmentOptions = [];
                    });
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .userList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        did: this.department ? this.department : 0,
                        is_locking: this.state ? this.state : 0,
                        keyword: this.search_content,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                page: this.page,
                                limit: this.limit,
                                did: this.department ? this.department : 0,
                                is_locking: this.state ? this.state : 0,
                                keyword: this.search_content,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.tableData = res.data.list;
                        this.pageTotal = res.data.count;
                    })
                    .catch(() => {
                        this.tableData = [];
                    });
            },
            departmentChange() {
                this.edit_team = ''
                this.getTeamList()
            },
            getTeamList() {
                this.$api.apiContent.departFromGroup({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.edit_department,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.edit_department,
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.edit_teams = res.data ? res.data : []
                }).catch(() => {
                    this.edit_teams = []
                })
            },
            getAllTeamList(){
                this.$api.apiContent.groupList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    name: this.search_content,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        name: this.search_content,
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.allTeams = res.data
                }).catch(() => {
                    this.allTeams = []
                })
            }
        },
        computed: {
            departmentSelect: function () {
                return this.departmentOptions.filter(function (item) {
                    return item.id !== 0;
                });
            },
            isHuiZhang(){
                return this.$store.state.user.mid === config.zhangque
            }
        },
        watch: {
            editVisible() {
                if (this.cur_id === -1 && this.editVisible) {
                    this.edit_name = "";
                    this.edit_account = "";
                    this.edit_department = "";
                    this.twice_group_leader = '';
                    this.edit_team = "";
                    this.edit_teams = [];
                    this.jid = "";
                    this.if_admin = false;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .dialog-container {
            margin-bottom: 20px;

            div {
                flex-shrink: 0;
                font-size: 18px;
                color: #333333;
            }

            .el-input {
                flex-shrink: 1;
                margin-left: 20px;
                font-size: 16px;
            }

            .el-select {
                flex-grow: 1;
                margin-left: 20px;
                font-size: 16px;
            }
        }

        .dialog-title {
            color: #000;
            font-size: 24px;
        }

        .dialog-footer {
            .el-button {
                width: 235px;
            }
        }

        .personnel-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .personnel-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    width: 268px;
                }
            }

            .personnel-list {
                padding: 34px 28px;
                min-height: 700px;

                .table-id {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-item {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-item-state {
                    text-align: center;
                    font-size: 16px;
                    color: red;

                    &.active {
                        color: green;
                    }
                }

                .table-operate {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .delete {
                        color: red;
                        margin-left: 30px;
                        margin-right: 30px;
                    }

                    .reset-pass {
                        margin-right: 30px;
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }
</style>
